import React from 'react';
import { FaPhone, FaMapMarkerAlt, FaClock } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer"  id="contacts">
      <div className="container footer__container">
        <div className="footer__content">
          <div className="footer__section">
            <h3>Контакты</h3>
            <div className="footer__contact-item">
              <FaPhone className="footer__icon" />
              <a href="tel:+79261507115">+7 (926) 150-71-15</a>
            </div>
            <div className="footer__contact-item">
              <FaMapMarkerAlt className="footer__icon" />
              <a 
                href="https://yandex.ru/maps/21641/lobnja/house/zapadnaya_ulitsa_1v/Z04YcgBjTkIHQFtsfXxxd39lZQ==/?ll=37.473770%2C56.006361&z=16.62" // здесь вставите ссылку на карту
                target="_blank" 
                rel="noopener noreferrer"
              >
                г. Лобня, ул. Западная, 1В
              </a>
            </div>
            <div className="footer__contact-item">
              <FaClock className="footer__icon" />
              <span>Пн-Сб: 11:00 - 20:00</span>
            </div>
          </div>
        </div>
        <div className="footer__bottom">
          <p>© {new Date().getFullYear()} Автоcервис. Все права защищены.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;