import React, { useState } from 'react';
import { FaPhone, FaMapMarkerAlt, FaBars, FaTimes } from 'react-icons/fa'; 
import './Header.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const scrollToSection = (e) => {
    e.preventDefault();
    const href = e.currentTarget.getAttribute('href');
    const section = document.querySelector(href);
    
    if (section) {
      section.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
      setIsMenuOpen(false); 
    }
  };

  return (
    <header className="header">
      <div className="container header__container">
        <div className="header__logo">
          АвтоСервис
        </div>
        <nav className={`header__nav ${isMenuOpen ? 'active' : ''}`}>
          <ul className="header__menu">
            <li><a href="#services" className="nav-link" onClick={scrollToSection}>Услуги</a></li>
            <li><a href="#about" className="nav-link" onClick={scrollToSection}>О нас</a></li>
            <li><a href="#contacts" className="nav-link" onClick={scrollToSection}>Контакты</a></li>
          </ul>
        </nav>

        <div className="header__phone">
          <div className="contact-item">
            <FaPhone className="contact-icon" />
            <a href="tel:+79261507115">+7 (926) 150-71-15</a>
          </div>
          <div className="contact-item">
            <FaMapMarkerAlt className="contact-icon" />
            <a 
              href="https://yandex.ru/maps/21641/lobnja/house/zapadnaya_ulitsa_1v/Z04YcgBjTkIHQFtsfXxxd39lZQ==/?ll=37.473770%2C56.006361&z=16.62" 
              className='address' 
              target="_blank" 
              rel="noopener noreferrer"
              style={{fontSize:"14px"}}
            >
              г. Лобня, ул. Западная, 1В
            </a>
          </div>
        </div>
        <button 
          className="header__burger"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-label={isMenuOpen ? 'Закрыть меню' : 'Открыть меню'}
        >
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>
    </header>
  );
};

export default Header;